import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTenants } from "Hooks/tenants";
import React from "react";

import MembersDisplayPerTenant from "./MemberPerTenant";

interface MembersListProps {
  name: string;
}

const SearchForMember: React.FC<React.PropsWithChildren<MembersListProps>> = ({
  name,
}) => {
  const { data } = useTenants();
  const tenants = data?.content || [];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">Tenant</TableCell>
            <TableCell component="th">Name</TableCell>
            <TableCell component="th">Email</TableCell>
            <TableCell component="th">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tenants.map((tenant) => {
            return (
              <MembersDisplayPerTenant key={tenant.name} tenant={tenant.name} name={name}></MembersDisplayPerTenant>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SearchForMember;
