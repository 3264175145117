import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useMembers } from "Hooks/members";
import React from "react";

interface MembersListProps {
  tenant: string,
  name: string
}
const MembersDisplayPerTenant: React.FC<React.PropsWithChildren<MembersListProps>> = ({
  tenant,
  name
}) => {
  const { data } = useMembers({
    sort: "createdAt,ASC",
    tenant,
    query: `.*${name}.*`,
    size: 100,
  });
  
  const members = data?.content || [];

  return (
    <>
    {members.map((member) => {
      return (
        <TableRow key={member.id}>
          <TableCell>{tenant}</TableCell>
          <TableCell>{member.name}</TableCell>
          <TableCell>{member.email}</TableCell>
          <TableCell>
            <Chip
              label={member.state}
              color={member.state === "ACTIVE" ? "success" : "error"}
              variant="outlined"
            />
          </TableCell>
        </TableRow>
      );
    })}
    </>
  );
};

export default MembersDisplayPerTenant;
