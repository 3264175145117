import { Auth, CognitoUser } from "@aws-amplify/auth";

export const getFreshJWT = () =>
  new Promise<string>(async (resolve, reject) => {
    try {
      const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err, session) => {
          if (err) reject(err);
          const { idToken } = session;
          resolve(idToken.jwtToken.toString());
        }
      );
    } catch (error) {
      reject(error);
    }
  });
