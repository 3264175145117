import { useLeanspaceAPI } from "@leanspace/js-client/dist/react";
import { Members } from "@leanspace/js-client/dist/services/Teams";
import { useQuery } from "react-query";

export const useMembers = (...args: Parameters<Members["getByTenant"]>) => {
  const { members } = useLeanspaceAPI();
  return useQuery(["member", ...args], () => members.getByTenant(...args));
};
export const useMember = (...params: Parameters<Members["get"]>) => {
  const { members } = useLeanspaceAPI();
  return useQuery(["member-details", ...params], () => members.get(...params));
};
